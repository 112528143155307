<template>
  <div class="login">
    <div
      class="container signup d-flex justify-content-center align-items-center"
    >
      <div class="container wrapper py-4 px-3">
        <div class="logo">
          <img src="@/assets/images/logo.jpeg" class="img-fliud" alt="" />
        </div>
        <form>
          <div class="input-box">
            <label for="">Email</label>
            <input
              type="text"
              class="form-control"
              v-model="email"
              placeholder="Enter your email address"
              required
            />
          </div>
          <div class="input-box">
            <label for="">Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Enter your password"
              v-model="password"
              required
            />
          </div>
          <div class="remember-forget mt-1">
            <router-link to="/forget-password">Forget Password?</router-link>
          </div>
          <button
            @click.prevent="login()"
            class="btn text-white"
            :disabled="loading"
          >
            <span class="text-white" v-if="loading">Loading...</span>
            <span class="text-white" v-else
              >Login <i class="bi bi-arrow-right"></i
            ></span>
          </button>
          <div class="register_link">
            <p>
              Don't have an account?
              <router-link to="/register">Register now</router-link>
            </p>
          </div>
        </form>

        <div class="footer">
          <p>Powered by</p>
          <img src="@/assets/images/footer.jpeg" class="img-footer" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { useToast } from "vue-toast-notification";
  import { useStore } from "vuex";

  const store = useStore();
  const email = ref("");
  const password = ref("");
  const loading = ref(false);

  const login = () => {
    if (email.value == "") {
      useToast().error("Email is required");
      return;
    }
    if (password.value == "") {
      useToast().error("Password is required");
      return;
    }

    loading.value = true;
    store
      .dispatch("post", {
        endpoint: "auth/login",
        details: {
          email: email.value,
          password: password.value,
        },
      })
      .then((resp) => {
        loading.value = false;
        console.log(resp);
        store.commit("setUser", resp.data);
        window.location.href = "/dashboard";
      })
      .catch((err) => {
        loading.value = false;
      });
  };
</script>
<style scoped lang="scss">
  .signup {
    min-height: 100vh;
  }
</style>
