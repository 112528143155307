<template>
  <div>
    <div class="completed-trip">
      <!-- ------------trips----------- -->
      <h2>Trips</h2>

      <div class="row">
        <div class="col-12">
          <div class="con" v-if="trips && trips.data.length > 0">
            <div
              class="d-flex align-items-center justify-content-between px-2 py-0"
              v-for="(item, index) in trips.data"
              :key="index"
            >
              <div class="d-flex align-items-center">
                <div class="svg ms-0" v-if="item.status == 0">
                  <svg
                    fill="#ffae00"
                    viewBox="0 0 256 256"
                    id="Flat"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#ffae00"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24ZM80,140a12,12,0,1,1,12-12A12.0006,12.0006,0,0,1,80,140Zm48,0a12,12,0,1,1,12-12A12.0006,12.0006,0,0,1,128,140Zm48,0a12,12,0,1,1,12-12A12.0006,12.0006,0,0,1,176,140Z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div class="svg ms-0" v-else>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    width="30"
                    viewBox="0 0 256 256"
                    xml:space="preserve"
                  >
                    <defs></defs>
                    <g
                      style="
                        stroke: none;
                        stroke-width: 0;
                        stroke-dasharray: none;
                        stroke-linecap: butt;
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10;
                        fill: none;
                        fill-rule: nonzero;
                        opacity: 1;
                      "
                      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                    >
                      <path
                        d="M 49.66 1.125 L 49.66 1.125 c 4.67 -2.393 10.394 -0.859 13.243 3.548 l 0 0 c 1.784 2.761 4.788 4.495 8.071 4.66 l 0 0 c 5.241 0.263 9.431 4.453 9.694 9.694 v 0 c 0.165 3.283 1.899 6.286 4.66 8.071 l 0 0 c 4.407 2.848 5.941 8.572 3.548 13.242 l 0 0 c -1.499 2.926 -1.499 6.394 0 9.319 l 0 0 c 2.393 4.67 0.859 10.394 -3.548 13.242 l 0 0 c -2.761 1.784 -4.495 4.788 -4.66 8.071 v 0 c -0.263 5.241 -4.453 9.431 -9.694 9.694 h 0 c -3.283 0.165 -6.286 1.899 -8.071 4.66 l 0 0 c -2.848 4.407 -8.572 5.941 -13.242 3.548 l 0 0 c -2.926 -1.499 -6.394 -1.499 -9.319 0 l 0 0 c -4.67 2.393 -10.394 0.859 -13.242 -3.548 l 0 0 c -1.784 -2.761 -4.788 -4.495 -8.071 -4.66 h 0 c -5.241 -0.263 -9.431 -4.453 -9.694 -9.694 l 0 0 c -0.165 -3.283 -1.899 -6.286 -4.66 -8.071 l 0 0 C 0.266 60.054 -1.267 54.33 1.125 49.66 l 0 0 c 1.499 -2.926 1.499 -6.394 0 -9.319 l 0 0 c -2.393 -4.67 -0.859 -10.394 3.548 -13.242 l 0 0 c 2.761 -1.784 4.495 -4.788 4.66 -8.071 l 0 0 c 0.263 -5.241 4.453 -9.431 9.694 -9.694 l 0 0 c 3.283 -0.165 6.286 -1.899 8.071 -4.66 l 0 0 c 2.848 -4.407 8.572 -5.941 13.242 -3.548 l 0 0 C 43.266 2.624 46.734 2.624 49.66 1.125 z"
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(0, 201, 43);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform=" matrix(1 0 0 1 0 0) "
                        stroke-linecap="round"
                      />
                      <polygon
                        points="36.94,66.3 36.94,66.3 36.94,46.9 36.94,46.9 62.8,35.34 72.5,45.04 "
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(0, 178, 42);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform="  matrix(1 0 0 1 0 0) "
                      />
                      <polygon
                        points="36.94,66.3 17.5,46.87 27.2,37.16 36.94,46.9 60.11,23.7 69.81,33.39 "
                        style="
                          stroke: none;
                          stroke-width: 1;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: rgb(255, 255, 255);
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform="  matrix(1 0 0 1 0 0) "
                      />
                    </g>
                  </svg>
                </div>
                <div class="text-col">
                  <h5>{{ item.holiday.name }}</h5>
                  <p>{{ formatDate(item.date) }}</p>
                </div>
              </div>
              <div class="">
                <span class="badge bg-success" v-if="item.paid == 1">paid</span>
                <span class="badge bg-primary" v-else>pending payment</span>
              </div>
            </div>
          </div>
          <p class="alert bg-primary" v-else>No trips available</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import store from "@/store";
  import { onMounted, ref } from "vue";
  import { formatDate } from "@/core/utils/helpers";

  const trips: any = ref(null);
  const getTrips = () => {
    store.dispatch("get", "get-trips").then((resp) => {
      trips.value = resp.data;
      console.log(trips.value);
    });
  };
  onMounted(() => {
    getTrips();
  });
</script>
