<template>
  <div></div>
</template>
<script lang="ts" setup>
  import store from "@/store";
  import { onMounted } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useToast } from "vue-toast-notification";
  const route = useRoute();
  const router = useRouter();
  onMounted(() => {
    console.log(route.query.reference);
    if (route.query.reference) {
      store.commit("setLoader", true);
      store
        .dispatch(
          "get",
          `verify-transaction?reference=${route.query.reference}`
        )
        .then((resp) => {
          console.log(resp);
          store.commit("setLoader", false);
          if (resp.data.status) {
            useToast().success("Transaction successful");
            router.push("/dashboard");
          } else {
            useToast().error(
              "Payment verification in progress, please refresh the page"
            );
            router.push("/dashboard");
          }
        })
        .catch(() => {
          useToast().error("Error verifying transaction");
          router.push("/dashboard");
        });
    }
  });
</script>
