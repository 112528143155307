<template>
  <div class="dashboard">
    <loader v-if="loading"></loader>
    <div class="d-flex justify-content-center align-items-center">
      <!--navbar starts-->
      <navbar></navbar>
      <!--navbar ends-->
      <div class="container content-wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  // import sidebar from "@/components/dashboard/sidebar.vue";
  import navbar from "@/components/dashboard/navbar.vue";
  import { useStore } from "vuex";
  import loader from "@/components/dashboard/loader.vue";
  import { computed } from "vue";

  const store = useStore();
  store.commit("getUser");
  const loading = computed(() => {
    return store.state.loader;
  });
  console.log(store.state.user);
</script>

<style lang="scss" scoped></style>
