<template>
  <div>
    <div class="holiday" v-if="upcoming">
      <div class="holiday-text">
        <h6>Upcoming Holiday</h6>
        <p>
          {{ upcoming.name }} <span>{{ formatDate(upcoming.date) }}</span>
        </p>
      </div>
      <div>
        <button
          class="btn apply-btn"
          data-bs-toggle="modal"
          data-bs-target="#applyRide"
        >
          Apply
        </button>
      </div>
    </div>
    <!-- --------------completed trip------------ -->
    <div class="completed-trip">
      <div class="row">
        <div class="col-6 col-content">
          <div
            class="col-con d-flex justify-content-between align-items-center px-3 py-2"
          >
            <div class="col-text">
              <h5>{{ kpi.trips }}</h5>
              <p>Completed trip</p>
            </div>
            <div>
              <i class="fa-solid fa-car-rear"></i>
            </div>
          </div>
        </div>
        <div class="col-6 col-content">
          <div
            class="col-con2 d-flex justify-content-between align-items-center px-3 py-2"
          >
            <div class="col-text">
              <h5>{{ kpi.children }}</h5>
              <p>Number of children</p>
            </div>
            <div>
              <i class="fa-solid fa-people-group"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- ------------trips----------- -->
      <h2>Trips</h2>

      <div class="row">
        <div class="col-12">
          <div class="" v-if="tloaded && trips.data.length > 0">
            <div
              class="con py-1 mb-1"
              v-for="(item, index) in trips.data"
              :key="index"
            >
              <div
                class="d-flex align-items-center justify-content-between px-2 py-0"
              >
                <div class="d-flex align-items-center">
                  <div class="svg ms-0" v-if="item.status == 0">
                    <svg
                      fill="#ffae00"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffae00"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24ZM80,140a12,12,0,1,1,12-12A12.0006,12.0006,0,0,1,80,140Zm48,0a12,12,0,1,1,12-12A12.0006,12.0006,0,0,1,128,140Zm48,0a12,12,0,1,1,12-12A12.0006,12.0006,0,0,1,176,140Z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div class="svg ms-0" v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      width="30"
                      viewBox="0 0 256 256"
                      xml:space="preserve"
                    >
                      <defs></defs>
                      <g
                        style="
                          stroke: none;
                          stroke-width: 0;
                          stroke-dasharray: none;
                          stroke-linecap: butt;
                          stroke-linejoin: miter;
                          stroke-miterlimit: 10;
                          fill: none;
                          fill-rule: nonzero;
                          opacity: 1;
                        "
                        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                      >
                        <path
                          d="M 49.66 1.125 L 49.66 1.125 c 4.67 -2.393 10.394 -0.859 13.243 3.548 l 0 0 c 1.784 2.761 4.788 4.495 8.071 4.66 l 0 0 c 5.241 0.263 9.431 4.453 9.694 9.694 v 0 c 0.165 3.283 1.899 6.286 4.66 8.071 l 0 0 c 4.407 2.848 5.941 8.572 3.548 13.242 l 0 0 c -1.499 2.926 -1.499 6.394 0 9.319 l 0 0 c 2.393 4.67 0.859 10.394 -3.548 13.242 l 0 0 c -2.761 1.784 -4.495 4.788 -4.66 8.071 v 0 c -0.263 5.241 -4.453 9.431 -9.694 9.694 h 0 c -3.283 0.165 -6.286 1.899 -8.071 4.66 l 0 0 c -2.848 4.407 -8.572 5.941 -13.242 3.548 l 0 0 c -2.926 -1.499 -6.394 -1.499 -9.319 0 l 0 0 c -4.67 2.393 -10.394 0.859 -13.242 -3.548 l 0 0 c -1.784 -2.761 -4.788 -4.495 -8.071 -4.66 h 0 c -5.241 -0.263 -9.431 -4.453 -9.694 -9.694 l 0 0 c -0.165 -3.283 -1.899 -6.286 -4.66 -8.071 l 0 0 C 0.266 60.054 -1.267 54.33 1.125 49.66 l 0 0 c 1.499 -2.926 1.499 -6.394 0 -9.319 l 0 0 c -2.393 -4.67 -0.859 -10.394 3.548 -13.242 l 0 0 c 2.761 -1.784 4.495 -4.788 4.66 -8.071 l 0 0 c 0.263 -5.241 4.453 -9.431 9.694 -9.694 l 0 0 c 3.283 -0.165 6.286 -1.899 8.071 -4.66 l 0 0 c 2.848 -4.407 8.572 -5.941 13.242 -3.548 l 0 0 C 43.266 2.624 46.734 2.624 49.66 1.125 z"
                          style="
                            stroke: none;
                            stroke-width: 1;
                            stroke-dasharray: none;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 10;
                            fill: rgb(0, 201, 43);
                            fill-rule: nonzero;
                            opacity: 1;
                          "
                          transform=" matrix(1 0 0 1 0 0) "
                          stroke-linecap="round"
                        />
                        <polygon
                          points="36.94,66.3 36.94,66.3 36.94,46.9 36.94,46.9 62.8,35.34 72.5,45.04 "
                          style="
                            stroke: none;
                            stroke-width: 1;
                            stroke-dasharray: none;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 10;
                            fill: rgb(0, 178, 42);
                            fill-rule: nonzero;
                            opacity: 1;
                          "
                          transform="  matrix(1 0 0 1 0 0) "
                        />
                        <polygon
                          points="36.94,66.3 17.5,46.87 27.2,37.16 36.94,46.9 60.11,23.7 69.81,33.39 "
                          style="
                            stroke: none;
                            stroke-width: 1;
                            stroke-dasharray: none;
                            stroke-linecap: butt;
                            stroke-linejoin: miter;
                            stroke-miterlimit: 10;
                            fill: rgb(255, 255, 255);
                            fill-rule: nonzero;
                            opacity: 1;
                          "
                          transform="  matrix(1 0 0 1 0 0) "
                        />
                      </g>
                    </svg>
                  </div>

                  <div class="text-col">
                    <h5>{{ item.holiday.name }}</h5>
                    <div class="">
                      <span class="badge bg-success" v-if="item.paid == 1"
                        >paid</span
                      >
                      <span class="badge bg-primary" v-else
                        >pending payment</span
                      >
                    </div>
                    <p>{{ formatDate(item.created_at) }}</p>
                  </div>
                </div>
                <div
                  class="d-flex flex-column justify-content-end"
                  v-if="item.paid != 1"
                >
                  <a
                    class="btn btn-success"
                    @click="setPrice(item)"
                    data-bs-toggle="modal"
                    data-bs-target="#accountModal"
                  >
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p v-else class="alert bg-primary">No trips available</p>
        </div>
      </div>
    </div>

    <!-- ------------children---------------- -->
    <div class="children">
      <div class="d-flex w-100 justify-content-between align-items-center my-4">
        <h3>Children</h3>
        <button
          class="add-btn"
          data-bs-target="#addChildren"
          data-bs-toggle="modal"
        >
          Add
        </button>
      </div>
      <div class="" v-if="cloaded && children.length > 0">
        <div class="card mb-2" v-for="(child, index) in children" :key="index">
          <div class="card-body py-2">
            {{ child.first_name }} {{ child.last_name }}
          </div>
        </div>
      </div>
      <p v-else class="alert bg-primary text-white">No children found</p>
    </div>

    <div
      class="modal fade"
      id="addChildren"
      tabindex="-1"
      aria-labelledby="addChildrenLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addChildrenLabel">Add Children</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">First Name</label>
                <input
                  type="text"
                  v-model="kid.first_name"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="">Last Name</label>
                <input
                  type="text"
                  v-model="kid.last_name"
                  class="form-control"
                />
              </div>

              <div class="form-group mt-2">
                <label for="">Gender</label>
                <select class="form-control" v-model="kid.gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div class="form-group">
                <label for="">Date of Birth</label>
                <input type="date" v-model="kid.dob" class="form-control" />
              </div>
              <div class="form-group mt-2">
                <label for="">Class</label>
                <select class="form-control" v-model="kid.class">
                  <option value="Primary 1">Primary 1</option>
                  <option value="Primary 2">Primary 2</option>
                  <option value="Primary 3">Primary 3</option>
                  <option value="Primary 4">Primary 4</option>
                  <option value="Primary 5">Primary 5</option>
                  <option value="Jss1">Jss1</option>
                  <option value="Jss2">Jss2</option>
                  <option value="Jss3">Jss3</option>
                  <option value="SSS1">SSS1</option>
                  <option value="SSS2">SSS2</option>
                  <option value="SSS3">SSS3</option>
                </select>
              </div>
              <div class="form-group mt-3">
                <button class="btn btn-primary" @click.prevent="addKid">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="applyRide"
      tabindex="-1"
      aria-labelledby="applyRideLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="applyRideLabel">Apply for Ride</h5>
            <button
              id="applyRideClose"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Select children</label>
                <div class="d-flex flex-wrap">
                  <label
                    :for="`cd-${child.id}`"
                    class="s-kid"
                    v-for="(child, index) in children"
                    :key="index"
                  >
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="`cd-${child.id}`"
                        v-model="apply.children"
                        :value="child.id"
                      />
                      <span class="form-check-label"
                        >{{ child.first_name }} {{ child.last_name }}</span
                      >
                    </div>
                  </label>
                </div>
              </div>

              <div class="form-group mt-2">
                <label for="">Pickup Location</label>
                <select class="form-control" v-model="apply.pickup_location">
                  <option value=""></option>
                  <option value="Mainland">Mainland</option>
                  <option value="Island">Island</option>
                  <option value="Special Request - Mainland">
                    Special Request - Mainland
                  </option>
                  <option value="Special Request - Island">
                    Special Request - Island
                  </option>
                  <option value="Special Request - Others">
                    Special Request - Other States
                  </option>
                </select>
              </div>
              <div
                class="form-group mt-2"
                v-if="apply.pickup_location == 'Special Request - Others'"
              >
                <label for="">Other Location</label>
                <select class="form-control" v-model="apply.other">
                  <option value="Ibadan">Ibadan</option>
                  <option value="Abeokuta">Abeokuta</option>
                  <option value="Abuja">Abuja</option>
                </select>
              </div>
              <div
                class="form-group"
                v-if="
                  apply.pickup_location &&
                  apply.pickup_location != 'Mainland' &&
                  apply.pickup_location != 'Island'
                "
              >
                <label for="">Address</label>
                <input
                  type="text"
                  v-model="apply.address"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="apply.notAvailable"
                    id="notAvailable"
                    value="true"
                  />
                  <label class="form-check-label" for="notAvailable"
                    >Someone will pick them up</label
                  >
                </div>
              </div>
              <div class="form-group" v-if="apply.notAvailable">
                <label for="">Contact Person Name</label>
                <input
                  type="text"
                  v-model="apply.contactName"
                  class="form-control"
                />
              </div>
              <div class="form-group" v-if="apply.notAvailable">
                <label for="">Contact Person Phone Number</label>
                <input
                  type="text"
                  v-model="apply.contactPhone"
                  class="form-control"
                />
              </div>
              <div class="form-group" v-if="apply.notAvailable">
                <label for="">Contact Person ID Card</label>
                <input type="file" class="form-control" id="idcard" />
              </div>

              <div class="price d-flex justify-content-between mt-3">
                <h5>Total</h5>
                <h5>NGN{{ formatPrice(price) }}</h5>
              </div>
              <p
                class="alert bg-primary text-white py-2 mt-2"
                v-if="
                  apply.pickup_location &&
                  apply.pickup_location != 'Island' &&
                  apply.pickup_location != 'Mainland'
                "
              >
                Additional cost will be applied to all special requests
              </p>
              <div class="form-group mt-3">
                <button
                  class="btn btn-primary w-100"
                  @click.prevent="applyRide()"
                >
                  Apply
                </button>
              </div>
              <div class="mt-3">
                <h6>Drop-off Location</h6>
                <h6 class="f-15 mt-1">Lagos Mainland:</h6>
                <div>
                  <p>Goshen Land Lifting Center - Livingspring Chapel Intl</p>
                  <p>Lagos NG, 117A Obafemi Awolowo Way, Ikea 100270</p>
                </div>
                <h6 class="f-15 mt-2">Lagos Island:</h6>
                <div>
                  <p>The Elevation Church, Ikoyi</p>
                  <p>40 Alfred Rewane Rd, Ikoyi, Lagos 106104, Lagos</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="accountModal"
      tabindex="-1"
      aria-labelledby="accountModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header py-2">
            <h6 class="modal-title">Confirm Payment</h6>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <p style="font-size: 14px">
                Transfer <b>NGN{{ formatPrice(modalPrice) }}</b> to the below
                account number and share the receipt to confirm your payment
              </p>
              <h5 class="mt-2">7852860442</h5>
              <h6>Buysimply - TRANSFOGLOBAL</h6>
              <h6 class="mb-2">Wema Bank</h6>
              <div>
                <a
                  class="btn btn-success f-14"
                  href="https://api.whatsapp.com/send/?phone=7030960708"
                  data-action="share/whatsapp/share"
                  >Share via Whatsapp</a
                >
                <a
                  class="btn btn-primary f-14 ms-1"
                  href="tel:+2347030960708"
                  data-action="share/whatsapp/share"
                  >Call Us</a
                >
              </div>
              <p class="mt-3">
                You can also call us or send us a message via whatsapp through
                the number below:
              </p>
              <h6>07030960708</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { computed, onMounted, ref } from "vue";
  import { useToast } from "vue-toast-notification";
  import { useStore } from "vuex";
  import { formatPrice, formatDate } from "@/core/utils/helpers";

  const store = useStore();
  const kpi = ref({
    children: 0,
    trips: 0,
  });
  const cloaded = ref(false);
  const tloaded = ref(false);
  const children: any = ref([]);
  const trips: any = ref(null);
  const upcoming: any = ref(null);
  const apply: any = ref({
    children: [],
    pickup_location: "",
    notAvailable: false,
    other: "",
    address: "",
    contactName: "",
    contactPhone: "",
  });
  const modalPrice: any = ref(0);
  const price = computed(() => {
    var price = 0;
    if (
      apply.value.pickup_location == "Mainland" ||
      apply.value.pickup_location == "Special Request - Mainland"
    ) {
      price = 15000;
    } else if (
      apply.value.pickup_location == "Island" ||
      apply.value.pickup_location == "Special Request - Island"
    ) {
      price = 20000;
    } else {
      price = 20000;
    }

    return price * apply.value.children.length;
  });

  // const triggerPayment = (id: any) => {
  //   store.commit("setLoader", true);
  //   store
  //     .dispatch("post", { endpoint: "pay-trip", details: { trip: id } })
  //     .then((resp) => {
  //       console.log(resp);
  //     });
  //   store.commit("setLoader", false);
  // };
  const applyRide = () => {
    if (apply.value.children.length < 1) {
      useToast().error("Select children");
      return;
    }
    if (apply.value.pickup_location.length < 1) {
      useToast().error("Pickup location is required");
      return;
    }
    if (
      apply.value.pickup_location != "Island" &&
      apply.value.pickup_location != "Mainland"
    ) {
      if (apply.value.address.length < 1) {
        useToast().error("Drop-off address is required");
        return;
      }
      if (
        apply.value.pickup_location == "Special Request - Others" &&
        apply.value.other.length < 1
      ) {
        useToast().error("Other location is required");
        return;
      }
    }
    if (apply.value.notAvailable && apply.value.contactName.length < 1) {
      useToast().error("Contact person name is required");
      return;
    }
    if (apply.value.notAvailable && apply.value.contactPhone.length < 1) {
      useToast().error("Contact person phone number is required");
      return;
    }

    console.log(apply.value);
    console.log("ddd", upcoming.value.id);
    var formData = new FormData();
    formData.append("children", JSON.stringify(apply.value.children));
    formData.append("notAvailable", apply.value.notAvailable);
    formData.append("pickup_location", apply.value.pickup_location);
    formData.append("contactName", apply.value.contactName);
    formData.append("contactPhone", apply.value.contactPhone);
    formData.append("other", apply.value.other);
    formData.append("holiday_id", upcoming.value.id);
    formData.append("address", apply.value.address);
    if (apply.value.notAvailable) {
      var file = document.getElementById("idcard") as HTMLInputElement;
      var img = file.files;
      if (!img) {
        useToast().error("Contact person Id card is required");
        return;
      }
      formData.append("image", img[0]);
    }

    store.commit("setLoader", true);
    store
      .dispatch("post", {
        endpoint: "apply-transport",
        details: formData,
      })
      .then((resp) => {
        store.commit("setLoader", false);
        console.log(resp.data);
        document.getElementById("applyRideClose")?.click();

        modalPrice.value = price.value;
        showModal();
        // window.location.href = resp.data.data.authorization_url;
      });
  };
  const kid = ref({
    first_name: "",
    last_name: "",
    class: "",
    gender: "",
    dob: "",
  });

  const showModal = () => {
    let myModal = new window.bootstrap.Modal(
      document.getElementById("accountModal"),
      {}
    );
    myModal.show();
  };
  const setPrice = (trip: any) => {
    modalPrice.value = trip.total;
  };
  const getKpi = () => {
    store.dispatch("get", "get-kpi").then((resp) => {
      kpi.value = resp.data;
    });
  };

  const addKid = () => {
    if (kid.value.first_name.length < 1) {
      useToast().error("First name is required");
      return;
    }
    if (kid.value.last_name.length < 1) {
      useToast().error("Last name is required");
      return;
    }
    if (kid.value.class.length < 1) {
      useToast().error("Class is required");
      return;
    }
    if (kid.value.gender.length < 1) {
      useToast().error("Gender is required");
      return;
    }
    if (kid.value.dob.length < 1) {
      useToast().error("Date of birth is required");
      return;
    }
    store.commit("setLoader", true);
    store
      .dispatch("post", { endpoint: "add-child", details: kid.value })
      .then(() => {
        store.commit("setLoader", false);
        useToast().success("Child added successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  const getChildren = () => {
    store.commit("setLoader", true);
    store.dispatch("get", "get-children").then((resp) => {
      store.commit("setLoader", false);
      children.value = resp.data;
      cloaded.value = true;
    });
  };
  const getTrips = () => {
    store.dispatch("get", "get-trips").then((resp) => {
      trips.value = resp.data;
      console.log(trips.value);
      tloaded.value = true;
    });
  };
  const getUpcomingTrip = () => {
    store.dispatch("get", "upcoming-trip").then((resp) => {
      upcoming.value = resp.data;
    });
  };

  onMounted(async () => {
    getKpi();
    getTrips();
    getUpcomingTrip();
    await getChildren();
    kid.value.last_name = store.state.user.last_name;
  });
</script>

<style scoped lang="scss">
  .f-15 {
    font-size: 15px;
  }
  .s-kid {
    border: 1px solid #0d0230;
    margin-right: 10px;
    padding: 11px 20px 11px 12px;
    margin-top: 5px;
    border-radius: 10px;
    .form-check-inline {
      margin-right: 0;
    }
  }
  .form-group {
    margin-top: 10px;
  }
</style>
