<template>
  <nav class="navbar-fixed position-fixed bnav">
    <div class="navbar-wrapper">
      <ul class="nav-items">
        <li
          :class="`nav-item ${currentRoute === 'dashboard' ? 'active' : null}`"
        >
          <a href="/dashboard" class="nav-link">
            <svg
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.03223 6.99902C3.03223 4.37381 3.06034 3.49902 6.53223 3.49902C10.0041 3.49902 10.0322 4.37381 10.0322 6.99902C10.0322 9.62424 10.0433 10.499 6.53223 10.499C3.02115 10.499 3.03223 9.62424 3.03223 6.99902Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0322 6.99902C14.0322 4.37381 14.0603 3.49902 17.5322 3.49902C21.0041 3.49902 21.0322 4.37381 21.0322 6.99902C21.0322 9.62424 21.0433 10.499 17.5322 10.499C14.0212 10.499 14.0322 9.62424 14.0322 6.99902Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.03223 17.999C3.03223 15.3738 3.06034 14.499 6.53223 14.499C10.0041 14.499 10.0322 15.3738 10.0322 17.999C10.0322 20.6242 10.0433 21.499 6.53223 21.499C3.02115 21.499 3.03223 20.6242 3.03223 17.999Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0322 17.999C14.0322 15.3738 14.0603 14.499 17.5322 14.499C21.0041 14.499 21.0322 15.3738 21.0322 17.999C21.0322 20.6242 21.0433 21.499 17.5322 21.499C14.0212 21.499 14.0322 20.6242 14.0322 17.999Z"
                stroke="#112A63"
                stroke-opacity="0.5"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="nav-link-text">Dashboard</span>
          </a>
        </li>
        <li :class="`nav-item ${currentRoute === 'trips' ? 'active' : null}`">
          <a href="/trips" class="nav-link">
            <i class="fa-solid fa-car-rear" style="font-size: 20px"></i>
            <span class="nav-link-text">Trips</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
  // import router from '@/router';
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  const route = useRoute();

  const store = useStore();
  const user = store.state.user;

  const showSidebar = computed(() => {
    return store.state.showSidebar;
  });
  const toggleSidebar = () => {
    store.commit("toggleSidebar", !showSidebar.value);
  };
  const currentRoute = route.name;
  console.log(currentRoute);
</script>

<style lang="scss" scoped>
  .bnav {
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
    background: white;
    .navbar-wrapper {
      max-width: 768px;
      width: 100%;
    }
    .nav-link {
      padding: 0;
    }
    .scn-svg-con {
      background-color: #061435;
    }
    .navbar-fixed {
      height: 80px;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 767px;
      background-color: #fff;
      -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      z-index: 999;
    }
    .nav-items {
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .nav-item {
      width: 50%;
      padding-top: 16px;
    }
    .nav-item .nav-link {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: rgba(17, 42, 99, 0.5);
      padding-left: 0;
      padding-right: 0;
    }
    .nav-item .nav-link span {
      text-transform: capitalize;
      font-size: 12px;
      margin-top: 8px;
    }
    .nav-item .nav-link svg {
      width: 24px;
      height: 24px;
    }
    .nav-item.active {
      border-top: 2px solid #0015ec;
    }
    .nav-item.active .nav-link svg {
      fill: #0015ec;
    }
    .nav-item.active .nav-link span {
      color: #0015ec;
    }
  }
</style>
