<template>
  <div class="login">
    <div
      class="container signup d-flex justify-content-center align-items-center"
    >
      <div class="container wrapper py-4 px-3">
        <div class="logo">
          <img src="@/assets/images/logo.jpeg" class="img-fliud" alt="" />
        </div>
        <form action="">
          <div class="input-box">
            <label for="">First Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your first name"
              v-model="first_name"
              required
            />
          </div>
          <div class="input-box">
            <label for="">Last Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your last name"
              v-model="last_name"
              required
            />
          </div>
          <div class="input-box">
            <label for="">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter your email address"
              v-model="email"
              required
            />
          </div>
          <div class="input-box">
            <label for="">Phone Number</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your phone number"
              required
              v-model="phone"
            />
          </div>
          <div class="input-box">
            <label for="">Address</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your home address"
              v-model="address"
            />
          </div>
          <div class="input-box">
            <label for="">Identification Card</label>
            <input type="file" class="form-control" id="idcard" />
          </div>
          <div class="input-box">
            <label for="">Password</label>
            <input
              type="password"
              class="form-control"
              placeholder="Enter your password"
              required
              v-model="password"
            />
          </div>
          <button @click.prevent="register()" class="btn" :disabled="loading">
            <span class="text-white" v-if="loading">Loading...</span>
            <span v-else class="text-white">
              Sign Up <i class="bi bi-arrow-right"></i
            ></span>
          </button>
          <div class="register_link">
            <p>
              Already have an account?
              <router-link to="/login">Login now</router-link>
            </p>
          </div>
        </form>

        <div class="footer">
          <p>Powered by</p>
          <img src="@/assets/images/footer.jpeg" class="img-footer" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref } from "vue";
  import { useToast } from "vue-toast-notification";
  import { useStore } from "vuex";

  const store = useStore();
  const email = ref("");
  const password = ref("");
  const first_name = ref("");
  const last_name = ref("");
  const phone = ref("");
  const address = ref("");
  const loading = ref(false);

  const register = () => {
    if (first_name.value == "") {
      useToast().error("First name is required");
      return;
    }
    if (last_name.value == "") {
      useToast().error("Last name is required");
      return;
    }
    if (phone.value == "") {
      useToast().error("Phone number is required");
      return;
    }
    if (address.value == "") {
      useToast().error("Address is required");
      return;
    }
    if (email.value == "") {
      useToast().error("Email is required");
      return;
    }
    if (password.value == "") {
      useToast().error("Password is required");
      return;
    }

    var file = document.getElementById("idcard") as HTMLInputElement;
    var img = file.files;
    if (!img) {
      useToast().error("Id card is required");
      return;
    }
    console.log(img);
    var formData = new FormData();
    formData.append("profile_image", img[0]);
    formData.append("first_name", first_name.value);
    formData.append("last_name", last_name.value);
    formData.append("phone", phone.value);
    formData.append("email", email.value);
    formData.append("address", address.value);
    formData.append("password", password.value);
    loading.value = true;
    store
      .dispatch("post", {
        endpoint: "auth/register",
        details: formData,
      })
      .then((resp) => {
        loading.value = false;
        useToast().success("Registration successful, please log in");
        window.setTimeout(() => {
          window.location.href = "/login";
        }, 1500);
      })
      .catch((err) => {
        loading.value = false;
      });
  };
</script>

<style scoped lang="scss">
  .login {
    .input-box {
      margin-top: 10px;
    }
  }
</style>
