import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import publicRoutes from "./public";
import managerRoutes from "./dashboard";
import Login from "@/views/public/login.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "login",
    component: Login,
  },
  {
    path: "/public",
    children: [...publicRoutes],
  },

  {
    path: "/dashboard",
    children: [...managerRoutes],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
