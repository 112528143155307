<template>
  <div class="login">
    <div
      class="container signup d-flex justify-content-center align-items-center"
      style="min-height: 100vh"
    >
      <div class="container wrapper py-4 px-3">
        <div class="logo">
          <img src="@/assets/images/logo.jpeg" class="img-fliud" alt="" />
        </div>
        <div class="hero-text">
          <h3 class="head-text">Forgot Password</h3>
          <span class="head-span" style="font-size: 15px"
            >Kindly input the email address linked to your account in order to
            reset your password.</span
          >
        </div>
        <form action="">
          <div class="input-box">
            <label for="">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter your email address"
              required
            />
            <div
              v-if="errors.email"
              class="error-msg text-danger ms-2"
              style="font-size: 11px"
            >
              {{ eMsg.email }}
            </div>
          </div>

          <button type="submit" class="btn">
            <span class="text-white" v-if="loading">Loading...</span>
            <span class="text-white" v-else>Submit</span>
          </button>
          <div class="register_link">
            <p>
              <router-link to="/login">Login</router-link>
            </p>
          </div>
        </form>

        <div class="footer">
          <p>Powered by</p>
          <img src="@/assets/images/footer.jpeg" class="img-footer" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script setup lang="ts">
  import { ref } from "vue";
  import { useStore } from "vuex";

  const store = useStore();
  const loading = ref(false);

  const email = ref("");
  const errors = ref({
    email: false,
  });
  const eMsg = ref({
    email: "This field is required",
  });

  const login = () => {
    if (email.value == "") {
      errors.value.email = true;
      return;
    } else if (
      !email.value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      errors.value.email = true;
      eMsg.value.email = "Invalid Email";
      return;
    } else {
      errors.value.email = false;
    }
    loading.value = true;
    window.location.href = `/otp?email=${email.value}`;
    // store
    //   .dispatch("post", {
    //     endpoint: "organization/forgotten-password",
    //     details: {
    //       email: email.value,
    //     },
    //   })
    //   .then(() => {
    //     loading.value = false;
    //     window.location.href = `/otp?email=${email.value}`;
    //   })
    //   .catch(() => {
    //     loading.value = false;
    //   });
  };
</script>
