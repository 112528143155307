import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/dashboard/layout.vue";
import Trip from "@/views/dashboard/trips.vue";
import Dashboard from "@/views/dashboard/index.vue";
import Verify from "@/views/dashboard/verify.vue";

const managerRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/trips",
        name: "trips",
        component: Trip,
      },
      {
        path: "/verify",
        name: "verify",
        component: Verify,
      },
    ],
  },
];

export default managerRoutes;
